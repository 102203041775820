import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import AllProjects from "../components/projects/allProjects";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/projects.css";

const Projects = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "projects");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Proyectos | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
				<meta property="og:title" content={INFO.main.title} />
				<meta property="og:description" content={currentSEO.description} />
				<meta property="og:type" content="website" />
			</Helmet>

			<div className="page-content">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="projects-container">
						<div className="title projects-title">
						Cosas que he hecho intentando dejar mi huella en el universo.
						</div>

						<div className="subtitle projects-subtitle">
						A lo largo de los años, he trabajado en una amplia gama de proyectos y estoy orgulloso del progreso alcanzado. Mi enfoque principal ha sido en el desarrollo de soluciones para la Comunidad Estudiantil, Empresarial e Industrial. Entre mis proyectos destacados se encuentran plataformas de productividad académica, CRM's y eCommerce.
						He colaborado en el desarrollo de tiendas en línea para empresas nacionales como ABB, lo que ha enriquecido mi experiencia en el diseño y la implementación de soluciones de comercio electrónico.
						Además, tengo una gran pasión por la automatización en el sector empresarial, especialmente en la optimización de CRM's, buscando siempre mejorar la eficiencia y la integración de los procesos.

 
						</div>

						<div className="projects-list">
							<AllProjects />
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Projects;
