const INFO = {
	main: {
		title: "Portafolio de Andrick Takisawa",
		name: "Andrick Takisawa",
		email: "andrick@incote.click",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/andricktakisawa",
		linkedin: "https://linkedin.com/in/andrick-takisawa/",
		instagram: "https://instagram.com/andricktakisawa",
	},

	homepage: {
		title: "Desarrollador de aplicaciones web full-stack y diseñador Web.",
		description:
			"Soy un programador FullStack con experiencia en Desarrollo Web. Tengo experiencia en la creación de aplicaciones y sitios web escalables, seguras y confiables utilizando varios marcos y tecnologías. Disfruto resolviendo problemas complejos y aprendiendo nuevas habilidades. Me apasiona crear código de alta calidad que siga las mejores prácticas y los estándares de la industria. Siempre estoy buscando nuevos desafíos y oportunidades para crecer como desarrollador.",
	},

	about: {
		title: "Soy Andrick Takisawa. Vivo en la Ciudad de México, donde desarrollo el futuro.",
		description:
			"Mi trayectoria en el mundo de la tecnología comenzó a los 10 años, cuando empecé a programar. A los 12 años, fundé mi propia marca, Incote, junto a un talentoso equipo, donde nos dedicamos a liberar el potencial de negocios y empresas mediante soluciones web personalizadas. A lo largo de los años, he tenido la oportunidad de colaborar con numerosos clientes, participando en el desarrollo, diseño, despliegue y posicionamiento de sus negocios a nivel nacional. Mi experiencia como programador FullStack en CWA ha sido fundamental para mi crecimiento profesional, donde he desarrollado eCommerce para diversas empresas nacionales, incluida la reconocida compañía sueca ABB. Actualmente, soy CTO y cofundador del proyecto “tumedic”, donde lidero el desarrollo de una plataforma completa que busca transformar el sector de la salud. Durante mi tiempo libre, también me dedico a dar clases de programación.",
	},

	// articles: {
	// 	title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
	// 	description:
	// 		"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	// },

	projects: [
		{
			title: "El Nopal Politécnico",
			description:
				"Software diseñado para potenciar la productividad estudiantil, ofreciendo herramientas para la organización, gestión del tiempo y aprendizaje interactivo",
			logo: "img/nopal.png",
			linkText: "Ver Proyecto Online",
			link: "https://elnopalpolitecnico.com/",
		},

		{
			title: "FuseStore",
			description:
				"Comercio electrónico completo creado para la empresa de Distribuidores Eléctricos Industriales: FuseStore.",
			logo: "img/fusestore.svg",
			linkText: "Ver Proyecto Online",
			link: "https://www.fusestore.com.mx/",
		},

		{
			title: "Gateway Sureste de México",
			description:
				"Comercio electrónico completo creado para la empresa de Distribuidores Eléctricos Industriales: Gateway Sureste de México.",
			logo: "img/gateway.png",
			linkText: "Ver Proyecto Online",
			link: "https://www.gatewaysureste.com/",
		},

		{
			title: "tumedic",
			description:
				"Plataforma web de gestión hospitalaria con todas las secciones necesarias para su funcionamiento, incluyendo gestión de citas, emisión de recetas y programación de cirugías, entre otras.",
			logo: "img/tumedic.png",
			linkText: "Ver Proyecto Online",
			link: "https://tumedic.mx",
		},

		{
			title: "TextChisel",
			description:
				"Plataforma de generación con Inteligencia Artificial que permite la creación de imágenes, chats, código, artículos y herramientas de marketing, potenciada con los servicios de OpenAI, Llama y Gemini.",
			logo: "img/textchisel.svg",
			linkText: "Ver Proyecto Online",
			link: "https://textchisel.tech",
		},
		{
			title: "Asther Store",
			description:
				"Sitio web de comercio electrónico personal completo. ",
			logo: "img/incote.png",
			linkText: "Ver Proyecto Online",
			link: "https://store.incote.cloud/",
		},
		{
			title: "Grupo Cardiel CRM",
			description:
				"Desarrollo de una plataforma CRM con secciones dedicadas tanto para el cliente como para la empresa. La plataforma incluye módulos de CRM, calendarios, bases de conocimiento, gestión de gastos, ingresos y egresos, contratos y presupuestos.",
			logo: "img/gcardiel.png",
			linkText: "",
			link: "#",
		},
		{
			title: "Greenbyte",
			description:
				"Landing page con formulario de contacto para una empresa de reciclaje de electrónicos.",
			logo: "img/greenbyte.svg",
			linkText: "Ver Proyecto Online",
			link: "https://incote.cloud/greenbyte/",
		},
		{
			title: "Bacalar - Inversiones Voeli",
			description:
				"Landing page para la venta de terrenos en Bacalar.",
			logo: "img/bacalar.ico",
			linkText: "Ver Proyecto Online",
			link: "https://inversionesvoeli.com/bacalar/",
		},
		{
			title: "Voeli",
			description:
				"Sitio web para Voeli, empresa de Bienes Raíces. Sitio web con información de la empresa y listado de propiedades conecatado a la API de EasyBroker.",
			logo: "img/voeli.png",
			linkText: "Ver Proyecto Online",
			link: "https://inversionesvoeli.com/",
		},
		{
			title: "Domus Prime",
			description:
				"Sitio web para Domus Prime, empresa de Bienes Raíces. Plataforma completa con información de la empresa y listado de propiedades con panel de administración.",
			logo: "img/domusprime.png",
			linkText: "Ver Proyecto Online",
			link: "https://domusprime.mx/",
		},
	],
};

export default INFO;
