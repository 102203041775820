import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Socials from "../components/about/socials";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/contact.css";

const Contact = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "contact");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`ContactO | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
				<meta property="og:title" content={INFO.main.title} />
				<meta property="og:description" content={currentSEO.description} />
				<meta property="og:type" content="website" />
			</Helmet>

			<div className="page-content">
				<NavBar active="contact" />
				<div className="content-wrapper">
					<div className="contact-logo-container">
						<div className="contact-logo">
							<Logo width={46} />
						</div>
					</div>

					<div className="contact-container">
						<div className="title contact-title">
						Pongámonos en contacto: formas de conectarse conmigo
						</div>

						<div className="subtitle contact-subtitle">
						Gracias por su interés en ponerse en contacto conmigo. 
						Agradezco sus comentarios, preguntas y sugerencias. S
						i tiene alguna pregunta o comentario específico, no dude en enviarme un correo electrónico directamente a
							&nbsp;{" "}
							<a href={`mailto:${INFO.main.email}`}>
								{INFO.main.email}
							</a>
							. Intento responder a todos los mensajes en un plazo de 24 horas, aunque puede que tarde más en periodos de mucha actividad. 
							También puede utilizar el formulario de contacto de mi sitio web para ponerte en contacto conmigo. 
							Simplemente rellene los campos obligatorios y me pondré en contacto con usted lo antes posible. 
							Por último, si prefiere conectarse a través de las redes sociales, puedes encontrarme en{" "}
							<a
								href={INFO.socials.instagram}
								target="_blank"
								rel="noreferrer"
							>
								{INFO.socials.instagram}
							</a>
							. Publico actualizaciones periódicas e interactúo con mis seguidores allí, 
							así que no dude en contactarme. ¡Gracias nuevamente por su interés y espero tener noticias suyas pronto!
						</div>
					</div>

					<div className="socials-container">
						<div className="contact-socials">
							<Socials />
						</div>
					</div>

					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Contact;
