const SEO = [
	{
		page: "home",
		description: "Andrick Takisawa es un apasionado desarrollador web FullStack con una profunda dedicación a la programación desde los diez años, considerándola su pasatiempo favorito. Su experiencia y proyectos destacan su habilidad para entregar trabajos completos y realizados a la perfección. Siempre está motivado por los desafíos y la resolución de problemas, buscando constantemente nuevas oportunidades para aprender y crecer.",
		keywords: ["Andrick Takisawa", "Ing. Takisawa", "Andrick T"],
	},

	{
		page: "about",
		description: "Andrick Takisawa es un apasionado desarrollador web FullStack con una profunda dedicación a la programación desde los diez años, considerándola su pasatiempo favorito. Su experiencia y proyectos destacan su habilidad para entregar trabajos completos y realizados a la perfección. Siempre está motivado por los desafíos y la resolución de problemas, buscando constantemente nuevas oportunidades para aprender y crecer.",
		keywords: ["Andrick Takisawa", "Ing. Takisawa", "Andrick T"],
		},

	{
		page: "articles",
		description: "Colección cronológica de mis pensamientos extensos sobre programación, liderazgo, diseño de productos y más.",
			keywords: ["Andrick Takisawa", "Ing. Takisawa", "Andrick T"],
		},

	{
		page: "projects",
		description: "Estos son los proyectos en los que Andrick Takisawa ha participado y que ha creado.",
			keywords: ["Andrick Takisawa", "Ing. Takisawa", "Andrick T"],
		},

	{
		page: "contact",
		description: "Si estás interesado en colaborar en un proyecto, no dudes en ponerte en contacto conmigo. Siempre estoy abierto a nuevas ideas y oportunidades.",
			keywords: ["Andrick Takisawa", "Ing. Takisawa", "Andrick T"],
		},
];

export default SEO;
